import * as React from "react"
import PropTypes from "prop-types"
import JuicerFeed from 'react-juicer-feed';


const Juicer = (props) => {
    const { juicerRef } = props;

    return (
        <div ref={juicerRef}>
            <JuicerFeed feedId='vivatech-2023' />
        </div>
    )
}

Juicer.propTypes = {
    juicerRef: PropTypes.any.isRequired,
}

export default Juicer
